import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import Font18 from "components/Fonts/Font18"
import { Grid } from "@mui/material"

const breadcrumbsData = [
  {
    title: "Strona Główna",
    link: "/",
  },
  "Firma",
]

const Company = () => {
  const { mediraBig, companyMembers } = useStaticQuery(
    graphql`
      query {
        mediraBig: file(name: { eq: "medira-logo-big" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 95
              placeholder: NONE
            )
          }
        }
        companyMembers: file(name: { eq: "company-members" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const titleImage = getImage(mediraBig)
  const companyMembersImage = getImage(companyMembers)

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Firma">
      <Header
        titleImage={titleImage}
        titleImageAlt={"Logo medira"}
        titleDescription={"Poznaj nas bliżej"}
        titleDescriptionStyles={{
          marginLeft: "64px",
          marginTop: "20px",
          fontWeight: 700,
          fontSize: "18px",
        }}
      />
      <Hr mb={4} />
      <Font42 mb={4}>Historia firmy</Font42>
      <Font18 textAlign="justify" mb={4}>
        Medira powstała w 2014 roku z inicjatywy Patrycji Mstowskiej i Beaty
        Pozimski. Założycielki firmy miały wówczas już wieloletnie doświadczenie
        w branży opieki nad osobami starszymi. Lata praktyki pokazały im, że
        wiedzą co jest ważne dla osób wyjeżdżających do pracy w charakterze
        opiekunów osób starszych, ale też czego wciąż brakuje na runku
        opiekuńczym. Medira i jej powstanie nie było niczym innym jak
        odpowiedzią na potrzeby polskich opiekunów.
      </Font18>
      <Font18 textAlign="justify" mb={8}>
        Od początku działalności firmy funkcjonowała ona w oparciu o twarde
        wartości i towarzyszącą im misję.
      </Font18>
      <Grid
        container
        columnSpacing={{ xs: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} lg={6}>
          <Font24 mb={4}>Medira teraz</Font24>
          <Font18 textAlign="justify" mb={4}>
            Każdy kolejny pracownik, który dołącza do Mediry wie, że jej
            funkcjonowanie bezpośrednio związane jest z jej wartościami:
          </Font18>

          <Font18 textAlign="justify">
            - zapewnieniem <strong>bezpieczeństwa</strong> klientom jak i
            pracownikom,
          </Font18>

          <Font18 textAlign="justify">
            - <strong>elastycznością</strong> w stosunku do personelu i
            podopiecznych,
          </Font18>

          <Font18 textAlign="justify">
            - najwyższą <strong>jakością</strong> świadczonych usług,
          </Font18>

          <Font18 textAlign="justify">
            - dbaniem o <strong>zdrowie</strong> członków zespołu,
          </Font18>
          <Font18 textAlign="justify" mt={-1} mb={4} ml={1}>
            partnerów, podopiecznych i pracowników.
          </Font18>
          <Font18 textAlign="justify" mb={4}>
            Misją naszej firmy od zawsze jest podnoszenie komfortu przebiegu
            późnej starości naszych podopiecznych. Wszystkie działania jakie
            podejmujemy w naszej organizacji odmieniamy przez nasze wartości.
          </Font18>
          <Font18 textAlign="justify" mb={4}>
            Medira to przestrzeń w której zrzeszamy odpowiedzialnych i
            zaangażowanych opiekunów osób starszych. Nasz personel to wysoce
            wykwalifikowany zespół ekspertów których zadaniem jest obsługa
            kandydatów, pracowników i klientów na najwyższym rynkowym poziomie.
          </Font18>
          <Font18 textAlign="justify" mb={4}>
            Obecnie pod naszą opieką znajduje się kilkuset klientów i setki
            opiekunów osób starszych.
          </Font18>
          <Font18 textAlign="justify" mb={4}>
            Czując niezwykły ciężar odpowiedzialności każdego dnia naszej pracy
            staramy się optymalizować nasze działania i procesy tak, aby
            obsługiwać Państwa w możliwie najbardziej efektywny sposób.
          </Font18>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          mb={{ xs: 4, lg: 4 }}
          container
          justifyContent="center"
        >
          <GatsbyImage image={companyMembersImage} alt="Pracownicy Medira" />
        </Grid>
      </Grid>

      <Font18 textAlign="justify" mb={4}>
        W ostatnich latach wprowadzaliśmy szereg usprawnień technologicznych i
        organizacyjnych, aby nasza praca była szybsza i jeszcze bardziej
        profesjonalna. Dziś możemy śmiało powiedzieć, że prowadzimy nowoczesny
        biznes opiekuńczy, który może zapewniać opiekę na najwyższym poziomie
        setkom klientów jednocześnie.
      </Font18>
      <Font18 textAlign="justify" mb={4}>
        W całym procesie udoskonalania naszej organizacji kluczem jest -
        człowiek. Każda wprowadzana przez nas zmiana służy pracownikowi,
        klientowi, podopiecznemu i partnerowi biznesowemu.
      </Font18>
      <Font18 textAlign="justify" mb={4}>
        Wiemy jak ważne w obecnych czasach jest poczucie przynależności do
        organizacji, która może zagwarantować bezpieczeństwo, stabilizacje i
        komfort - dlatego codziennie dokładamy wszelkich starań, aby umacniać
        naszą pozycję na rynku branży opiekuńczej - stając się tym samym coraz
        pewniejszym i bardziej godnym zaufania pracodawcą, partnerem biznesowym
        i usługobiorcą.
      </Font18>
    </Layout>
  )
}

export default Company
